*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    background-color: $body-bg;
    color: $body-color;
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    margin: 0;
    text-align: left;
}

@at-root {
    @-ms-viewport {
        width: device-width;
    }
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: $headings-margin-bottom;
    margin-top: 0;
}

p {
    margin-bottom: $paragraph-margin-bottom;
    margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
    border-bottom: 0;
    cursor: help;
    text-decoration: underline;
    text-decoration: underline dotted;
}

address {
    font-style: normal;
    line-height: inherit;
    margin-bottom: 1rem;
}

ol,
ul,
dl {
    margin-bottom: 1rem;
    margin-top: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: $dt-font-weight;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}

a {
    background-color: transparent;
    color: $link-color;
    text-decoration: $link-decoration;

    svg {
        fill: $link-color;
    }
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;

        svg {
            fill: $link-hover-color;
        }
    }
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;

    @include hover-focus {
        color: inherit;
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }
}

pre,
code,
kbd,
samp {
    font-family: $font-family-monospace;
    font-size: 1em;
}

pre {
    margin-bottom: 1rem;
    margin-top: 0;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    border-style: none;
    vertical-align: middle;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    caption-side: bottom;
    color: $table-caption-color;
    padding-bottom: $table-cell-padding;
    padding-top: $table-cell-padding;
    text-align: left;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: $label-margin-bottom;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

// button,
// html [type="button"],
// [type="reset"],
// [type="submit"]{
// }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

// input[type="date"],
// input[type="time"],
// input[type="datetime-local"],
// input[type="month"]{
// }

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    border: 0;
    margin: 0;
    min-width: 0;
    padding: 0;
}

legend {
    color: inherit;
    display: block;
    font-size: 1.5rem;
    line-height: inherit;
    margin-bottom: 0.5rem;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    width: 100%;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
}

// [type="search"]::-webkit-search-cancel-button,
// [type="search"]::-webkit-search-decoration{
// }

::-webkit-file-upload-button {
    font: inherit;
}

output {
    display: inline-block;
}

summary {
    cursor: pointer;
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

p,
li,
a,
div,
legend,
span {
    /* stylelint-disable */
    -webkit-text-size-adjust: 100%; /* stylelint-enable */
}

//experiment on focus on/off

body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) label:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) section:focus,
body:not(.user-is-tabbing) span:focus,
body:not(.user-is-tabbing) div:focus,
body:not(.user-is-tabbing) h3:focus {
    outline: none !important;
    text-decoration: none;
}

//Experiment to fix broken focus on progress bar.

body.user-is-tabbing *:focus,
body.user-is-tabbing input:focus ~ .dds__card-label-container {
    outline: $custom-focus-outline !important;
    outline-offset: $custom-focus-offset !important;
    /* for non-webkit browsers */
    outline-offset: $custom-focus-offset -webkit-focus-ring-color !important;
}

/* a subtle focus style for keyboard-input elements */
.text-input:focus {
    outline: 0.0625rem solid $dell-granite;
    /* Adjust to suit your tastes */
}

/* no outline for non-keyboard-inputs elements */
button:focus,
select:focus {
    outline: none;
}

caption {
    caption-side: unset;
    color: $dell-moon;
    padding-bottom: 0.5714rem;
    padding-top: 0.5714rem;
    text-align: left;
}

////////////////////////////
// Previously in type scss
///////////////////////////
input,
button,
select,
optgroup,
textarea {
    font-family: $font-family-sans-serif;
    font-size: inherit;
    line-height: inherit;
    margin: 0; // Remove the margin in Firefox and Safari
}

small {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    line-height: $small-line-height;
}

mark {
    background-color: $mark-bg;
    padding: $mark-padding;
}

//
// Horizontal rules
//
hr {
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
    margin-bottom: $hr-margin-y;
    margin-top: $hr-margin-y;
}

h1,
h2,
h3 {
    color: $headings-color;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    margin-bottom: $headings-margin-bottom;
}

h4,
h5,
h6 {
    color: $headings-color;
    font-family: $headings-font-family;
    font-weight: $font-weight-normal;
    line-height: $headings-line-height;
    margin-bottom: $headings-margin-bottom;
}

h1 {
    font-size: $h1-font-size;
    font-weight: 300;
}

h2 {
    font-size: $h2-font-size;
    font-weight: 300;
}

h3 {
    font-size: $h3-font-size;
    font-weight: 300;

    &.dds__feature {
        font-weight: 300;
    }
}

h4 {
    font-size: $h4-font-size;
    font-weight: 400;
}

h5 {
    font-size: $h5-font-size;
    font-weight: 400;
}

h6 {
    font-size: $h6-font-size;
    font-weight: 400;
}

@include media-breakpoint-up(xs) {
    h1 {
        font-size: 1.5rem; // ~24px
        line-height: 1.75rem; // ~28px
    }

    h2 {
        font-size: 1.25rem; // ~20px
        line-height: 1.5rem; // ~24px
    }

    h3 {
        font-size: 1.0625rem; // ~17px
        line-height: 1.25rem; // ~20px

        &.dds__feature {
            font-size: 1.125rem; // ~18px
            line-height: 1.375rem; // ~22px
        }
    }

    h4 {
        font-size: 1rem; // ~16px
        line-height: 1.25rem; // ~20px
    }

    h5 {
        font-size: 0.875rem; // ~14px
        line-height: 1.25rem; // ~20px
    }

    h6 {
        font-size: 0.75rem; // ~12px
        line-height: 1.25rem; // ~20px
    }
}

@include media-breakpoint-up(sm) {
    // h1 {
    //   font-size: 1.5rem; // ~24px
    //   line-height: 1.75rem; // ~28px
    //   font-weight: 300;
    // }

    // h2 {
    //   font-size: 1.25rem; // ~20px
    //   line-height: 1.5rem; // ~24px
    //   font-weight: 300;
    // }

    // h3 {
    //   font-size: 1.0625rem; // ~17px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 300;

    //   &.dds__feature {
    //     font-size: 1.125rem; // ~18px
    //     line-height: 1.375rem; // ~22px
    //     font-weight: 300;
    //   }
    // }

    // h4 {
    //   font-size: 1rem; // ~16px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 400;
    // }

    // h5 {
    //   font-size: 1rem; // ~16px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 400;
    // }

    // h6 {
    //   font-size: .875rem; // ~14px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 400;
    // }
}

@include media-breakpoint-up(md) {
    h1 {
        font-size: 1.875rem; // ~30px
        line-height: 2.25rem; // ~36px
    }

    h2 {
        font-size: 1.625rem; // ~26px
        line-height: 1.875rem; // ~30px
    }

    h3 {
        font-size: 1.25rem; // ~20px
        line-height: 1.5rem; // ~24px

        &.dds__feature {
            font-size: 1.375rem; // ~22px
            line-height: 1.625rem; // ~26px
        }
    }

    h4 {
        font-size: 1.125rem; // ~18px
        line-height: 1.375rem; // ~22px
    }

    h5 {
        font-size: 1rem; // ~16px
        line-height: 1.25rem; // ~20px
    }

    h6 {
        font-size: 0.875rem; // ~14px
        line-height: 1.25rem; // ~20px
    }
}

@include media-breakpoint-up(lg) {
    h1 {
        font-size: 2rem; // ~32px
        line-height: 2.5rem; // ~40px
    }

    h2 {
        font-size: 1.75rem; // ~28px
        line-height: 2rem; // ~32px
    }

    h3 {
        font-size: 1.25rem; // ~20px
        line-height: 1.5rem; // ~24px

        &.dds__feature {
            font-size: 1.5rem; // ~24px
            line-height: 1.75rem; // ~28px
        }
    }

    h4 {
        font-size: 1.125rem; // ~18px
        line-height: 1.375rem; // ~22px
    }

    h5 {
        font-size: 1rem; // ~16px
        line-height: 1.25rem; // ~20px
    }

    h6 {
        font-size: 0.875rem; // ~14px
        line-height: 1.25rem; // ~20px
    }
}

////////////////////////////
// Previously in code scss
///////////////////////////
code {
    color: $code-color;
    font-size: $code-font-size;
    word-break: break-word;

    a > & {
        color: inherit;
    }
}

kbd {
    @include border-radius($border-radius-sm);
    @include box-shadow($kbd-box-shadow);
    background-color: $kbd-bg;
    color: $kbd-color;
    font-size: $kbd-font-size;
    padding: $kbd-padding-y $kbd-padding-x;

    kbd {
        @include box-shadow(none);
        font-size: 100%;
        font-weight: $nested-kbd-font-weight;
        padding: 0;
    }
}

pre {
    color: $pre-color;
    display: block;
    font-size: $code-font-size;

    code {
        color: inherit;
        font-size: inherit;
        word-break: normal;
    }
}

////////////////////////////
// Previously in forms scss
///////////////////////////
legend {
    font-size: 0.875rem;
}

////////////////////////////
// Previously in dividers scss
///////////////////////////
section {
    padding: 0.3125rem;
}
