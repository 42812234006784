@charset "UTF-8";
/*!
* Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
* Copyright 2011-2018 The Bootstrap Authors
* Copyright 2011-2018 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  background-color: #fff;
  color: #000000;
  font-family: "Roboto", "Cordia New", "Microsoft Sans Serif", "Utsaah", "Devanagari MT", "Nirmala UI", "Latha", "InaiMathi", "Gautami", "Telugu Sangam MN", "Tunga", "Kannada Sangam MN", "Kartika", "Malayalam Sangam MN", "Shruti", "Nirmala UI", "Gujarati MT", "Gujarati Sangam MN", "Vrinda", "Bangla Sangam MN", "Meiryo UI Reg", "メイリオ Reg", "MS UI Gothic Reg", "Hiragino Kaku Gothic Reg", "ヒラギノ角ゴ Pro W3 Reg", "Microsoft YaHei", "微软雅黑", "Hiragino Sans GB", "Microsoft JhengHei", "微軟正黑體", "Malgun Gothic", "맑은 고딕", "Gulim", "AppleGothic", "Apple LiGothic", "LiHei Pro", "Osaka", "STHeiti", "华文黑体", "STXihei", "华文细黑", "SimHei", "黑体", "Arial Unicode MS", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.625rem;
  margin-top: 0;
}

p {
  margin-bottom: 0.625rem;
  margin-top: 0;
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: underline;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-bottom: 1rem;
  margin-top: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  background-color: transparent;
  color: #006bbd;
  text-decoration: none;
}

a svg {
  fill: #006bbd;
}

a:hover {
  color: #00447c;
  text-decoration: underline;
}

a:hover svg {
  fill: #00447c;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-bottom: 1rem;
  margin-top: 0;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  caption-side: bottom;
  color: #767676;
  padding-bottom: 0.5714rem;
  padding-top: 0.5714rem;
  text-align: left;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.25rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  color: inherit;
  display: block;
  font-size: 1.5rem;
  line-height: inherit;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  font: inherit;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

p,
li,
a,
div,
legend,
span {
  /* stylelint-disable */
  -webkit-text-size-adjust: 100%;
  /* stylelint-enable */
}

body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) label:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus,
body:not(.user-is-tabbing) section:focus,
body:not(.user-is-tabbing) span:focus,
body:not(.user-is-tabbing) div:focus,
body:not(.user-is-tabbing) h3:focus {
  outline: none !important;
  text-decoration: none;
}

body.user-is-tabbing *:focus,
body.user-is-tabbing input:focus ~ .dds__card-label-container {
  outline: 0.0625rem solid #000000 !important;
  outline-offset: 0.125rem !important;
  /* for non-webkit browsers */
  outline-offset: 0.125rem -webkit-focus-ring-color !important;
}

/* a subtle focus style for keyboard-input elements */
.text-input:focus {
  outline: 0.0625rem solid #c8c9c7;
  /* Adjust to suit your tastes */
}

/* no outline for non-keyboard-inputs elements */
button:focus,
select:focus {
  outline: none;
}

caption {
  caption-side: unset;
  color: #767676;
  padding-bottom: 0.5714rem;
  padding-top: 0.5714rem;
  text-align: left;
}

input,
button,
select,
optgroup,
textarea {
  font-family: "Roboto", "Cordia New", "Microsoft Sans Serif", "Utsaah", "Devanagari MT", "Nirmala UI", "Latha", "InaiMathi", "Gautami", "Telugu Sangam MN", "Tunga", "Kannada Sangam MN", "Kartika", "Malayalam Sangam MN", "Shruti", "Nirmala UI", "Gujarati MT", "Gujarati Sangam MN", "Vrinda", "Bangla Sangam MN", "Meiryo UI Reg", "メイリオ Reg", "MS UI Gothic Reg", "Hiragino Kaku Gothic Reg", "ヒラギノ角ゴ Pro W3 Reg", "Microsoft YaHei", "微软雅黑", "Hiragino Sans GB", "Microsoft JhengHei", "微軟正黑體", "Malgun Gothic", "맑은 고딕", "Gulim", "AppleGothic", "Apple LiGothic", "LiHei Pro", "Osaka", "STHeiti", "华文黑体", "STXihei", "华文细黑", "SimHei", "黑体", "Arial Unicode MS", "Arial", sans-serif;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

small {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25;
}

mark {
  background-color: #eeeeee;
  padding: 0.2em;
}

hr {
  border: 0;
  border-top: 0.0625rem solid #cccccc;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

h1,
h2,
h3 {
  color: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0.625rem;
}

h4,
h5,
h6 {
  color: inherit;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.625rem;
}

h1 {
  font-size: 2rem;
  font-weight: 300;
}

h2 {
  font-size: 1.75rem;
  font-weight: 300;
}

h3 {
  font-size: 1.25rem;
  font-weight: 300;
}

h3.dds__feature {
  font-weight: 300;
}

h4 {
  font-size: 1.125rem;
  font-weight: 400;
}

h5 {
  font-size: 1rem;
  font-weight: 400;
}

h6 {
  font-size: 0.875rem;
  font-weight: 400;
}

h1 {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h3 {
  font-size: 1.0625rem;
  line-height: 1.25rem;
}

h3.dds__feature {
  font-size: 1.125rem;
  line-height: 1.375rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.25rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

h6 {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  h2 {
    font-size: 1.625rem;
    line-height: 1.875rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h3.dds__feature {
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  h6 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  h2 {
    font-size: 1.75rem;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  h3.dds__feature {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  h4 {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  h6 {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

code {
  color: #b7295a;
  font-size: 87.5%;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  border-radius: 0.0625rem;
  background-color: #212529;
  color: #fff;
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
}

kbd kbd {
  font-size: 100%;
  font-weight: 700;
  padding: 0;
}

pre {
  color: #212529;
  display: block;
  font-size: 87.5%;
}

pre code {
  color: inherit;
  font-size: inherit;
  word-break: normal;
}

legend {
  font-size: 0.875rem;
}

section {
  padding: 0.3125rem;
}
